/* TODO remove unneeded colors */
:root {
	--color-green: #58ce97;
	--color-orange: #cf4f27;
	--color-red: #d14758;
	--color-pale-pink: #ef6e85;
	--color-blue: #4c88e9;
	--color-black: #242e39;
	--color-gray-1: #3b424b;
	--color-gray-2: #68707d;
	--color-gray-3: #949fab;
	--color-gray-4: #c7cdd4;
	--color-gray-5: #edf1f6;
	--color-gray-6: #f7f9fb;
	--color-white: #fff;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}

.button,
.button-save,
.button-remove,
.menu-button {
	display: flex;
	align-items: center;
	justify-content: center;

	height: 32px;

	margin: 0;
	padding: 0 8px;

	border: 0;
	border-radius: 4px;
	background: transparent;
	color: currentColor;

	font-family: inherit;
	font-size: inherit;
	line-height: 1;
	font-weight: inherit;

	white-space: nowrap;
	cursor: pointer;
}

.menu-button {
	width: 32px;
	padding: 0;
}

.button:hover,
.button.is-active,
.menu-button:hover,
.menu-button.is-active {
	background-color: var(--color-gray-5);
	color: var(--color-black);
}

.button:disabled,
.menu-button:disabled {
	color: var(--color-gray-4);
}

.audio-transcription-wrapper {
	position: relative;
	display: flex;
	align-items: center;
}

.transcribing-text {
	display: flex;
	align-items: center;
	height: 32px;
	margin: 0;
	border: 0;
	border-radius: 4px;
	background: transparent;
	color: currentColor;

	font-family: inherit;
	font-size: inherit;
	line-height: 1;
	font-weight: inherit;

	white-space: nowrap;
	cursor: pointer;

	width: 32px;
	padding: 0;

	margin-left: 12px; /* 16 pixels to the right of the button */
	user-select: none; /* Make the text non-selectable */
	cursor: default; /* Make the text non-clickable */
}
