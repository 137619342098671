/* TODO remove unneeded colors */
:root {
	--color-green: #58ce97;
	--color-orange: #cf4f27;
	--color-red: #d14758;
	--color-pale-pink: #ef6e85;
	--color-blue: #4c88e9;
	--color-black: #242e39;
	--color-gray-1: #3b424b;
	--color-gray-2: #68707d;
	--color-gray-3: #949fab;
	--color-gray-4: #c7cdd4;
	--color-gray-5: #edf1f6;
	--color-gray-6: #f7f9fb;
	--color-white: #fff;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	color: #45657b;
	font-family: roboto;
	font-size: 15px;
	line-height: normal;
}

a {
	color: var(--color-blue);
	cursor: pointer;
}

p {
	margin: 0;
}

p + p {
	margin-top: 16px;
}

kbd,
code {
	padding: 4px;

	border-radius: 4px;
	background-color: var(--color-gray-6);
	color: var(--color-gray-1);

	box-decoration-break: clone;

	font-size: 14px;
}

.button,
.button-save,
.button-remove,
.menu-button {
	display: flex;
	align-items: center;
	justify-content: center;

	height: 32px;

	margin: 0;
	padding: 0 8px;

	border: 0;
	border-radius: 4px;
	background: transparent;
	color: currentColor;

	font-family: inherit;
	font-size: inherit;
	line-height: 1;
	font-weight: inherit;

	white-space: nowrap;
	cursor: pointer;
}

.menu-button {
	width: 32px;
	padding: 0;
}

.button:hover,
.button.is-active,
.menu-button:hover,
.menu-button.is-active {
	background-color: var(--color-gray-5);
	color: var(--color-black);
}

.button:disabled,
.menu-button:disabled {
	color: var(--color-gray-4);
}

.button-save {
	background-color: var(--color-green);
	color: var(--color-white);
}

.button-remove {
	background-color: var(--color-red);
	color: var(--color-white);
}

.button-remove:disabled {
	background-color: var(--color-pale-pink);
}

/* Basic editor styles */
.ProseMirror {
	padding: 48px 8px 8px;

	border: 1.5px solid var(--color-gray-4);
	min-height: 100%;
	background: #f9f9f9;
	resize: vertical;
	overflow: auto;
	width: 100%;
}

div:has(> .ProseMirror) {
	height: 100%;
}

.editor-mini .ProseMirror {
	padding-top: 8px;
}

.ProseMirror-focused {
	border: 2.5px solid var(--color-orange);
	outline: none;
}

.ProseMirror > * + * {
	margin-top: 16px;
}

/* Placeholder text */
.tiptap p.is-editor-empty:first-child::before {
	color: #adb5bd;
	content: attr(data-placeholder);
	float: left;
	height: 0;
	pointer-events: none;
	font-style: italic;
}
